import EasyStar from 'easystarjs'
import Soldier from '../prefabs/Soldier'
import Alien from '../prefabs/Alien'
import Bullet from '../prefabs/Bullet'
import EnemyBullet from '../prefabs/EnemyBullet'
import {
  TILE_SIZE, SCENES, EVENTS, GAME_STATE, KEYBOARD
} from '../consts'

class WorldScene extends Phaser.Scene {
  constructor() {
    super(SCENES.WORLD)

    this.isTeamSelected = false
    this.unitSelected = null
    this.gameState = GAME_STATE.STARTED
  }

  init(config) {
    this.config = config
    this.hudScene = this.scene.get(SCENES.HUD)
    this.events.emit(EVENTS.DISPLAY_HUD)
    this.events.emit(EVENTS.START_GAME)
  }

  create() {
    this.createMap()
    this.createCursor()
    this.createCameraControls()
    this.createPathfinding()
    this.createKeyboardEvents()
    this.createEvents()
    this.createAnimations()
    this.createGroups()
    this.setCollisions()
    this.addSounds()

    this.hudScene.listSquads(this.playerSquads)
  }

  update(time, delta) {
    this.controls.update(delta)

    if (this.unitSelected) {
      this.cursor.setAlpha(1)
      const worldPoint = this.input.activePointer.positionToCamera(this.cameras.main)
      const pointerTileX = this.map.worldToTileX(worldPoint.x)
      const pointerTileY = this.map.worldToTileY(worldPoint.y)
      this.cursor.x = this.map.tileToWorldX(pointerTileX)
      this.cursor.y = this.map.tileToWorldY(pointerTileY)
      this.cursor.setVisible(!this.checkCollision(pointerTileX, pointerTileY))
    }
  }

  setCollisions() {
    Object.keys(this.playerSquads).forEach((squad) => {
      this.physics.add.collider(this.playerSquads[squad], this.obstaclesLayer)
      this.physics.add.overlap(this.playerSquads[squad], this.enemyBullets, this.damageEnemy.bind(this))
    })

    this.physics.add.overlap(this.enemies, this.bullets, this.damageEnemy.bind(this))
  }

  addSounds() {
    this.gunSound = this.sound.add('gunSound')
    this.soundConfig = {
      mute: false,
      volume: 0.01,
      rate: 1,
      detune: 0,
      seek: 0,
      loop: false,
      delay: 0
    }
  }

  createAnimations() {
    this.anims.create({
      key: 'soldierFrontMove',
      frames: this.anims.generateFrameNumbers('soldier', {
        start: 1, end: 2
      }),
      frameRate: 10,
      repeat: -1
    })

    this.anims.create({
      key: 'soldierFront',
      frames: [{
        key: 'soldier', frame: 0
      }],
      frameRate: 20
    })

    this.anims.create({
      key: 'soldierBack',
      frames: [{
        key: 'soldier', frame: 3
      }],
      frameRate: 20
    })

    this.anims.create({
      key: 'front',
      frames: [{
        key: 'soldier', frame: 0
      }],
      frameRate: 20
    })

    this.anims.create({
      key: 'soldierBackMove',
      frames: this.anims.generateFrameNumbers('soldier', {
        start: 4, end: 5
      }),
      frameRate: 10,
      repeat: -1
    })
  }

  createMap() {
    this.add.image(0, 0, 'bg').setScale(2)
    this.map = this.make.tilemap({ key: 'level1' })
    this.tiles = this.map.addTilesetImage('d', 'map')
    this.backgroundLayer = this.map.createDynamicLayer('background', this.tiles, 0, 0)
    this.obstaclesLayer = this.map.createStaticLayer('obstacles', this.tiles, 0, 0)
    this.obstaclesLayer.setCollisionBetween(50, 200)
  }

  createCursor() {
    this.cursor = this.add.image(32, 32, 'cursor')
    this.cursor.setScale(0.5)
    this.cursor.setOrigin(0)
    this.cursor.alpha = 0
  }

  createGroups() {
    this.playerSquads = []
    this.enemies = this.physics.add.group({
      classType: Alien, runChildUpdate: true
    })
    this.bullets = this.physics.add.group({
      classType: Bullet, runChildUpdate: true
    })
    this.enemyBullets = this.physics.add.group({
      classType: EnemyBullet, runChildUpdate: true
    })

    let spawnX = 4 * TILE_SIZE
    let spawnY = 16 * TILE_SIZE

    this.config.playerData.teams.forEach((squad) => {
      const squadPhysicsGroup = this.physics.add.group({
        classType: Soldier, runChildUpdate: true
      })

      squad.units.forEach((unit) => {
        const unitData = this.config.gameConfig.units.humans.find((unitData) => unitData.id === squad.type)
        squadPhysicsGroup.add(new Soldier(this, spawnX, spawnY, unitData.sprite, unit.health, unitData.speed, unit.name, unitData.damage, unitData.fireRate, unitData.range, unit.accuracy, unit.rank))
        spawnX += TILE_SIZE
      })
      this.playerSquads[squad.name] = squadPhysicsGroup
    })

    let spawnX2 = 48 * TILE_SIZE
    let spawnY2 = 4 * TILE_SIZE
    let activationInterval = 1000
    const {
      sprite, health, speed, damage, fireRate, range, accuracy
    } = this.config.gameConfig.units.aliens[0]
    const alien1 = new Alien(this, spawnX2, spawnY2, sprite, health, speed, damage, fireRate, range, accuracy, activationInterval)
    const alien2 = new Alien(this, spawnX2, spawnY2 + 96, sprite, health, speed, damage, fireRate, range, accuracy, activationInterval + 1000)
    const alien3 = new Alien(this, spawnX2, spawnY2 + 406, sprite, health, speed, damage, fireRate, range, accuracy, activationInterval + 2000)
    this.enemies.addMultiple([alien1, alien2, alien3])
  }

  createCameraControls() {
    this.cursors = this.input.keyboard.createCursorKeys()

    this.camera = this.cameras.main
    this.camera.setBounds(0, 0, this.map.widthInPixels, this.map.heightInPixels)

    this.controls = new Phaser.Cameras.Controls.FixedKeyControl({
      camera: this.camera,
      left: this.cursors.left,
      right: this.cursors.right,
      up: this.cursors.up,
      down: this.cursors.down,
      speed: 0.5
    })
  }

  createPathfinding() {
    this.finder = new EasyStar.js() // eslint-disable-line

    this.map.grid = []
    for (let y = 0; y < this.map.height; y++) {
      const col = []
      for (let x = 0; x < this.map.width; x++) {
        // In each cell we store the ID of the tile, which corresponds
        // to its index in the tileset of the map ("ID" field in Tiled)
        col.push(this.getTileID(x, y))
      }
      this.map.grid.push(col)
    }

    this.finder.setGrid(this.map.grid)
    this.finder.setAcceptableTiles([-1])
  }

  getTileID(x, y) {
    const tile = this.map.getTileAt(x, y, 'obstacles')
    return tile && tile.index
  }

  checkCollision(x, y) {
    const tile = this.map.getTileAt(x, y, 'obstacles')
    return tile.properties.collides === true
  }

  moveUnit(pointer) {
    if (!this.unitSelected) return

    const soldier = this.unitSelected
    const x = this.camera.scrollX + pointer.x
    const y = this.camera.scrollY + pointer.y
    const toX = Math.floor(x / TILE_SIZE)
    const toY = Math.floor(y / TILE_SIZE)
    const fromX = Math.floor(soldier.x / TILE_SIZE)
    const fromY = Math.floor(soldier.y / TILE_SIZE)

    console.log('going from (' + fromX + ',' + fromY + ') to (' + toX + ',' + toY + ')')

    this.finder.findPath(fromX, fromY, toX, toY, (path) => {
      if (!path) {
        console.warn('Path was not found.')
      } else {
        soldier.move(path)
      }
    })

    this.finder.calculate()
  }

  getEnemy(x, y, distance, enemyType) {
    let enemyUnits = []

    if (enemyType === 'Soldier') {
      Object.keys(this.playerSquads).forEach((squad) => {
        this.playerSquads[squad].getChildren().forEach((unit) => {
          enemyUnits.push(unit)
        })
      })
    } else {
      enemyUnits = this.enemies.getChildren()
    }

    for (let i = 0; i < enemyUnits.length; i++) {
      const enemy = enemyUnits[i]

      if (enemy.active && Phaser.Math.Distance.Between(x, y, enemy.x, enemy.y) <= distance) {
        return enemy
      }
    }

    return false
  }

  addBullet(x, y, angle, damage) {
    let bullet = this.bullets.getFirstDead()

    if (!bullet) {
      bullet = new Bullet(this, 0, 0, damage)
      this.bullets.add(bullet)
    }

    bullet.fire(x, y, angle)
    this.gunSound.play(this.soundConfig)
  }

  addEnemyBullet(x, y, angle, damage) {
    let bullet = this.enemyBullets.getFirstDead()

    if (!bullet) {
      bullet = new EnemyBullet(this, 0, 0, damage)
      this.enemyBullets.add(bullet)
    }

    bullet.fire(x, y, angle)
  }

  damageEnemy(enemy, bullet) {
    if (enemy.active === true && bullet.active === true) {
      bullet.setActive(false)
      bullet.setVisible(false)

      enemy.recieveDamage(bullet.damage)

      enemy.tint = 0xff0000

      this.time.delayedCall(500, () => {
        enemy.tint = 0xffffff
      })
    }

    if (enemy instanceof Soldier) {
      this.events.emit(EVENTS.UPDATE_SOLDIER_STATS, enemy)
    }
  }

  createKeyboardEvents() {
    const EscKey = this.input.keyboard.addKey(KEYBOARD.CANCEL_SELECTION)
    EscKey.on(EVENTS.DOWN, () => {
      if (this.unitSelected) {
        this.unitSelected.deSelect()
      }

      this.unitSelected = null
      this.cursor.setAlpha(0)
    })

    const SpaceKey = this.input.keyboard.addKey(KEYBOARD.PAUSE_GAME)
    SpaceKey.on(EVENTS.DOWN, () => {
      this.scene.pause()
      this.scene.launch(SCENES.GAME_OPTIONS)
      this.gameState = GAME_STATE.PAUSED
    })
  }

  createEvents() {
    this.input.on(EVENTS.POINTER_UP, (e) => this.moveUnit(e))
    this.events.on(EVENTS.UNIT_SELECTED, (unit) => {
      if (this.unitSelected) {
        this.unitSelected.deSelect()
      }

      this.unitSelected = unit
      this.unitSelected.select()
    })
  }

  debug() {
    const debugGraphics = this.add.graphics().setAlpha(0.75)
    this.obstaclesLayer.renderDebug(debugGraphics, {
      tileColor: null, // Color of non-colliding tiles
      collidingTileColor: new Phaser.Display.Color(243, 134, 48, 255), // Color of colliding tiles
      faceColor: new Phaser.Display.Color(40, 39, 37, 255) // Color of colliding face edges
    })
  }
}

export default WorldScene
