import WebFont from 'webfontloader'
import {
  SCENES, FONTS
} from '../consts'

class BootScene extends Phaser.Scene {
  constructor() {
    super({ key: SCENES.BOOT })
  }

  preload () {
    this.fontsReady = false
    this.fontsLoaded = this.fontsLoaded.bind(this)

    WebFont.load({
      google: { families: [FONTS.DEFAULT, FONTS.HEADER, FONTS.TITLE] },
      active: this.fontsLoaded
    })

    this.load.image('logo', require('../assets/images/logo.png'))
  }

  fontsLoaded () {
    this.fontsReady = true
  }

  update () {
    if (this.fontsReady) {
      this.scene.start(SCENES.LOADING)
    }
  }
}

export default BootScene
