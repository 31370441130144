import {
  SCENES, EVENTS, GAME_STATE, KEYBOARD
} from '../consts'
class GameOptions extends Phaser.Scene {
  constructor() {
    super({ key: SCENES.GAME_OPTIONS })
  }

  init() {
    this.gameScene = this.scene.get(SCENES.WORLD)
  }

  create() {
    this.setupUIElements()

    this.add.tween({
      targets: [this.levelText, this.bgGraphics],
      ease: 'Sine.easeInOut',
      duration: 500,
      delay: 0,
      alpha: {
        getStart: () => 0,
        getEnd: () => 1
      }
    })

    const SpaceKey = this.input.keyboard.addKey(KEYBOARD.PAUSE_GAME)
    SpaceKey.on(EVENTS.DOWN, () => this.resumeGame())
  }

  setupUIElements() {
    const width = this.cameras.main.width
    const height = this.cameras.main.height

    this.bgGraphics = this.add.graphics({
      fillStyle: {
        color: 0x000000, alpha: 0.5
      }
    })
    this.bg = new Phaser.Geom.Rectangle(0, 0, width, height)
    this.bgGraphics.fillRectShape(this.bg)

    this.levelText = this.add.text(0, 0, 'Game Paused ||', {
      fontSize: '40px', fill: '#fff'
    })

    Phaser.Display.Align.In.Center(
      this.levelText,
      this.add.zone(width / 2, height / 2, width, height)
    )
  }

  resumeGame() {
    this.gameScene.gameState = GAME_STATE.STARTED
    this.scene.resume(SCENES.WORLD)
    this.scene.stop()

    this.add.tween({
      targets: [this.levelText, this.bgGraphics],
      ease: 'Sine.easeInOut',
      duration: 500,
      delay: 0,
      alpha: {
        getStart: () => 1,
        getEnd: () => 0
      }
    })
  }
}

export default GameOptions
