import Phaser from 'phaser'
import { SCENES } from './consts'
import TitleScene from './scenes/Title'
import WorldScene from './scenes/World'
import BootScene from './scenes/Boot'
import LoadingScene from './scenes/Loading'
import HUD from './scenes/HUD'
import GameOptionsScene from './scenes/GameOptions'

const titleScene = new TitleScene()
const worldScene = new WorldScene()
const bootScene = new BootScene()
const loadingScene = new LoadingScene()
const HUDScene = new HUD()
const gameOptionsScene = new GameOptionsScene()

const config = {
  type: Phaser.AUTO,
  width: 800,
  height: 600,
  physics: {
    default: 'arcade',
    arcade: { gravity: { y: 0 } }
  }
}

function newGame () {
  if (game) return
  game = new Phaser.Game(config)
  game.scene.add(SCENES.TITLE, titleScene)
  game.scene.add(SCENES.WORLD, worldScene)
  game.scene.add(SCENES.BOOT, bootScene)
  game.scene.add(SCENES.LOADING, loadingScene)
  game.scene.add(SCENES.HUD, HUDScene)
  game.scene.add(SCENES.GAME_OPTIONS, gameOptionsScene)
  game.scene.start(SCENES.BOOT)
}

function destroyGame () {
  if (!game) return
  game.destroy(true)
  game.runDestroy()
  game = null
}

let game

if (module.hot) {
  module.hot.dispose(destroyGame)
  module.hot.accept(newGame)
}

if (!game) newGame()
