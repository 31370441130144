import Unit from './Unit'
import {
  TILE_SIZE, COVER
} from '../consts'

class Alien extends Unit {
  constructor(scene, x, y, sprite, health, speed, damage, fireRate, range, accuracy, activationInterval) {
    super(scene, x, y, sprite, health, speed, accuracy)

    this.damage = damage
    this.fireRate = fireRate
    this.range = range
    this.activationInterval = activationInterval
    this.isActivated = false

    this.scene.add.existing(this)
  }

  ai() {
    if (this.cover === COVER.NONE) {
      this.runForCover()
    }
  }

  getClosestCover(fromX, fromY) {
    const queue = this.scene.map.getTilesWithin(fromX, fromY, 10, 10, 'obstacles')

    for (let i = 0; i < queue.length; i++) {
      const tile = queue[i]

      if (tile.properties.collides) {
        if (tile.x === fromX && tile.y === fromY) {
        } else {
          // Todo: select free tile next to cover
          // 2. return NEXT TO tile from that cover on correct side
          // this.scene.add.rectangle(tile.x * TILE_SIZE, tile.y * TILE_SIZE, TILE_SIZE, TILE_SIZE, 0xff0000).setOrigin(0)
          return [tile.x + 1, tile.y]
        }
      }
    }

    return [-1, -1]
  }

  runForCover() {
    const fromX = Math.floor(this.x / TILE_SIZE)
    const fromY = Math.floor(this.y / TILE_SIZE)
    let [toX, toY] = this.getClosestCover(fromX, fromY)
    
    if (toX === -1) {
      // Move towards enemy
      toX = fromX - 4
      toY = fromY
    }

    this.scene.finder.findPath(fromX, fromY, toX, toY, (path) => {
      if (!path) {
        console.warn('Path was not found.')
      } else {
        this.move(path)
      }
    })
    this.scene.finder.calculate()
  }

  fire() {
    const enemy = this.scene.getEnemy(this.x, this.y, this.range, 'Soldier')

    if (enemy) {
      const angle = Phaser.Math.Angle.Between(this.x, this.y, enemy.x, enemy.y)
      this.scene.addEnemyBullet(this.x, this.y, angle, this.damage)
      // this.angle = (angle + Math.PI / 2) * Phaser.Math.RAD_TO_DEG
    }
  }

  update(time) {
    if (time > this.activationInterval && !this.isActivated) {
      this.ai()
      this.isActivated = true
    }

    if (time > this.nextTic) {
      this.fire()
      this.nextTic = time + this.fireRate
    }
  }
}

export default Alien
