import {
  SCENES, EVENTS, KEYBOARD, FONTS
} from '../consts'
class HUD extends Phaser.Scene {
  constructor() {
    super({
      key: SCENES.HUD, active: true
    })

    this.squadsTextGroups = []
  }

  init() {
    this.gameScene = this.scene.get(SCENES.WORLD)
  }

  create() {
    this.setupUIElements()
    this.setupEvents()

    const debugKey = this.input.keyboard.addKey(KEYBOARD.DEBUG)
    let doDebug = false
    debugKey.on(EVENTS.DOWN, () => {
      doDebug = !doDebug
      this.debug(doDebug)
    })
  }

  listSquads(squads) {
    let startY = 28
    Object.keys(squads).forEach((squad) => {
      this.listSquad(squads[squad], squad, undefined, startY)
      startY += 110
    })
  }

  listSquad(squad, squadName, startX = 10, startY = 28) {
    let yPos = startY
    this.add.text(startX, yPos, `${squadName}`, {
      fontFamily: FONTS.HEADER, fontSize: '14px', fill: '#fff'
    })
    squad.getChildren().forEach((unit) => {
      yPos += 20
      const rank = unit.rank ? unit.rank.substr(0, 1).toUpperCase() : ''
      const health = unit.health
      const cover = unit.cover.substr(0, 1).toUpperCase()
      const unitText = this.add.text(startX, yPos, `${rank} ${unit.name} (${health}) ${cover}`, {
        fontFamily: FONTS.DEFAULT, fontSize: '12px', fill: '#fff'
      })

      this.squadsTextGroups[unit.name] = unitText
    })
  }

  setupUIElements() {
    this.levelText = this.add.text(0, 0, 'Welcome to hell...', {
      fontFamily: FONTS.TITLE, fontSize: '40px', fill: '#fff'
    })
    this.unitHeader = this.add.text(5, 5, 'Units', {
      fontFamily: FONTS.HEADER, fontSize: '18px', fill: '#fff'
    })

    const width = this.cameras.main.width
    const height = this.cameras.main.height

    Phaser.Display.Align.In.Center(
      this.levelText,
      this.add.zone(width / 2, height / 2, width, height)
    )
    this.hideUIElements()
  }

  hideUIElements() {
    this.unitHeader.alpha = 0
    this.levelText.alpha = 0
  }

  setupEvents() {
    this.gameScene.events.on(EVENTS.DISPLAY_HUD, () => {
      this.unitHeader.alpha = 1
    })

    this.gameScene.events.on(EVENTS.UPDATE_SOLDIER_STATS, (unit) => {
      const unitText = this.squadsTextGroups[unit.name]
      if (unit.health <= 0) {
        unitText.setStroke(0x000000, 2)
        return
      }

      unitText.setText(`${unit.rank.substr(0, 1).toUpperCase()} ${unit.name} (${unit.health}) ${unit.cover.substr(0, 1).toUpperCase()}`)
    })

    this.gameScene.events.on(EVENTS.START_GAME, () => {
      this.levelText.alpha = 1

      this.add.tween({
        targets: this.levelText,
        ease: 'Sine.easeInOut',
        duration: 1000,
        delay: 2000,
        alpha: {
          getStart: () => 1,
          getEnd: () => 0
        }
      })
    })
  }

  debug(doDebug) {
    if (doDebug) {
      this.listSquad(this.gameScene.enemies, 700)
    }
  }
}

export default HUD
