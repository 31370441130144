import Unit from './Unit'
import {
  EVENTS, COVER, TILE_SIZE
} from '../consts'

class Soldier extends Unit {
  constructor(scene, x, y, sprite, health, speed, name, damage, fireRate, range, accuracy, rank) {
    super(scene, x, y, sprite, health, speed, accuracy)

    this.name = name
    this.damage = damage
    this.fireRate = fireRate
    this.range = range
    this.rank = rank
    this.isSelected = false
    this.selection = [this.addCircle(x, y), this.addText(), this.addHealth(x, y)]
    this.setInteractive({ useHandCursor: true })
      .on(EVENTS.POINTER_UP, () => {
        if (!this.isSelected) {
          this.select()
        } else {
          this.deSelect()
        }

        this.scene.events.emit(EVENTS.UNIT_SELECTED, this)
      })

    this.scene.add.existing(this)

    this.anims.load(['soldierFrontMove', 'soldierFront', 'soldierBackMove', 'soldierBack'])
  }

  select() {
    this.isSelected = true
    this.selection[0].setAlpha(1)
    this.selection[1].setAlpha(1)
    this.selection[2].setAlpha(1)
  }

  deSelect() {
    this.isSelected = false
    this.selection[0].setAlpha(0)
    this.selection[1].setAlpha(0)
    this.selection[2].setAlpha(0)
  }

  addCircle(x, y) {
    return this.scene.add.circle(x, y, 12).setOrigin(-0.2)
      .setStrokeStyle(1, this.rank === 'Team Leader' ? 0xf4f442 : 0x1a65ac)
      .setAlpha(0)
  }

  addText() {
    return this.scene.add.text(this.x, this.y + 20, this.name, { fontSize: 10 })
      .setAlpha(0)
  }

  addHealth(x, y) {
    return this.scene.add.text(x, y + 30, `Health: ${this.health}`, { fontSize: 10 })
      .setAlpha(0)
  }

  lookForCover() {
    super.lookForCover()

    this.scene.events.emit(EVENTS.UPDATE_SOLDIER_STATS, this)
  }

  move(path) {
    this.scene.tweens.killTweensOf([this, this.selection[0]])

    const tweens = []
    const fromX = this.x
    const fromY = this.y
    let completedTweens = 0

    for (let i = 0; i < path.length - 1; i++) {
      const ex = path[i + 1].x
      const ey = path[i + 1].y
      let direction = 'up'

      tweens.push({
        targets: [this, this.selection[0]],
        x: {
          value: ex * this.scene.map.tileWidth, duration: this.speed
        },
        y: {
          value: ey * this.scene.map.tileHeight, duration: this.speed
        },
        onStart: () => {
          if (completedTweens === 0) {
            this.isMoving = true

            if (this.y / TILE_SIZE <= ey) {
              direction = 'down'
              this.anims.play('soldierFrontMove', true)
            } else {
              direction = 'up'
              this.anims.play('soldierBackMove', true)
            }

            this.cover = COVER.NONE
            this.relieveSpace(fromX, fromY)
            this.scene.events.emit(EVENTS.UPDATE_SOLDIER_STATS, this)
          }
        },
        onComplete: () => {
          completedTweens++

          if (tweens.length === completedTweens) {
            this.isMoving = false
            direction === 'down' ? this.anims.play('soldierBack', true) : this.anims.play('soldierFront', true)
            this.lookForCover(this.scene.map)
            this.takeUpSpace(this.x, this.y)
          }

          this.selection[1].setAlpha(0)
          this.selection[2].setAlpha(0)
        }
      })
    }

    this.scene.tweens.timeline({ tweens })
  }

  fire() {
    const enemy = this.scene.getEnemy(this.x, this.y, this.range)

    if (enemy) {
      const angle = Phaser.Math.Angle.Between(this.x, this.y, enemy.x, enemy.y)
      this.scene.addBullet(this.x, this.y, angle, this.damage)
      // this.angle = (angle + Math.PI / 2) * Phaser.Math.RAD_TO_DEG
    }
  }

  recieveDamage(damage) {
    let modifiedDamage = 0

    if (this.cover === COVER.HALF) {
      modifiedDamage = damage / 4
    } else if (this.cover === COVER.FULL) {
      modifiedDamage = damage / 2
    }

    this.health -= damage - modifiedDamage

    if (this.health <= 0) {
      this.setActive(false)
      this.setVisible(false)
      this.relieveSpace(this.x, this.y)
      this.selection[0].setAlpha(0)
      this.selection[1].setAlpha(0)
      this.selection[2].setAlpha(0)

      this.addCorpse(15, 1)

      if (this.isSelected) {
        this.scene.unitSelected = null
      }
    }
  }

  update(time) {
    if (time > this.nextTic && !this.isMoving) {
      this.fire()
      this.nextTic = time + this.fireRate
    }
  }
}

export default Soldier
