import { SCENES } from '../consts'

class TitleScene extends Phaser.Scene {
  constructor() {
    super(SCENES.TITLE)
  }

  create() {
    this.scene.start(SCENES.BOOT)
  }
}

export default TitleScene
