import {
  COVER, TILE_SIZE
} from '../consts'
class Unit extends Phaser.GameObjects.Sprite {
  constructor(scene, x, y, sprite, health, speed, accuracy) {
    super(scene, x, y, sprite)

    this.scene = scene
    this.x = x
    this.y = y
    this._health = health || 100
    this._speed = speed || 200
    this._accuracy = accuracy
    this._cover = COVER.NONE
    this.isMoving = false
    this.nextTic = 0

    this.setOrigin(0)

    this.scene.add.existing(this)

    this.takeUpSpace(x, y)
  }

  set health(health) {
    this._health = health
  }

  get health() {
    return this._health
  }

  set speed(speed) {
    this._speed = speed
  }

  get speed() {
    return this._speed
  }

  set cover(cover) {
    this._cover = cover
  }

  get cover() {
    return this._cover
  }

  set accuracy(accuracy) {
    this.accuracy = accuracy
  }

  get accuracy() {
    return this.accuracy
  }

  move(path) {
    const tweens = []
    const fromX = this.x
    const fromY = this.y
    let completedTweens = 0

    for (let i = 0; i < path.length - 1; i++) {
      const ex = path[i + 1].x
      const ey = path[i + 1].y
      tweens.push({
        targets: [this],
        x: {
          value: ex * this.scene.map.tileWidth, duration: this.speed
        },
        y: {
          value: ey * this.scene.map.tileHeight, duration: this.speed
        },
        onStart: () => {
          if (completedTweens === 0) {
            this.isMoving = true
            this.cover = COVER.NONE
            this.relieveSpace(fromX, fromY)
          }
        },
        onComplete: () => {
          completedTweens++

          if (tweens.length === completedTweens) {
            this.isMoving = false
            this.lookForCover()
            this.takeUpSpace(this.x, this.y)
          }
        }
      })
    }

    this.scene.tweens.timeline({ tweens })
  }

  takeUpSpace(x, y) {
    const tileX = Math.floor(x / TILE_SIZE)
    const tileY = Math.floor(y / TILE_SIZE)
    const tile = this.scene.map.getTileAt(tileX, tileY, 'obstacles')
    tile.properties.collides = true
  }

  relieveSpace(x, y) {
    // this.map.worldToTileX(worldPoint.x)
    const tileX = Math.floor(x / TILE_SIZE)
    const tileY = Math.floor(y / TILE_SIZE)
    const tile = this.scene.map.getTileAt(tileX, tileY, 'obstacles')

    tile.properties.collides = false
  }

  recieveDamage (damage) {
    let modifiedDamage = 0

    if (this.cover === COVER.HALF) {
      modifiedDamage = damage / 4
    } else if (this.cover === COVER.FULL) {
      modifiedDamage = damage / 2
    }

    this.health -= damage - modifiedDamage

    if (this.health <= 0) {
      this.setActive(false)
      this.setVisible(false)
      this.addCorpse()
    }
  }

  lookForCover() {
    const tileX = this.x / TILE_SIZE
    const tileY = this.y / TILE_SIZE
    const queue = [
      {
        x: tileX - 1, y: tileY - 1
      }, {
        x: tileX, y: tileY - 1
      }, {
        x: tileX + 1, y: tileY - 1
      }, {
        x: tileX - 1, y: tileY
      }, {
        x: tileX + 1, y: tileY
      }, {
        x: tileX - 1, y: tileY + 1
      }, {
        x: tileX, y: tileY + 1
      }, {
        x: tileX + 1, y: tileY + 1
      }]

    for (let i = 0; i < queue.length; i++) {
      const vector = queue[i]
      if (this.checkCollision(vector.x, vector.y)) {
        this.cover = COVER.FULL
        return
      }
    }

    this.cover = COVER.NONE
  }

  checkCollision(x, y) {
    return this.scene.map.getTileAt(x, y, 'obstacles').properties.collides
  }

  addCorpse(tileX = 54, tileY = 3) {
    const fromX = Math.floor(this.x / TILE_SIZE)
    const fromY = Math.floor(this.y / TILE_SIZE)

    const tile = this.scene.backgroundLayer.getTileAt(tileX, tileY, 'background')
    this.scene.backgroundLayer.putTileAt(tile, fromX, fromY)
  }

  update() {
  }
}

export default Unit
