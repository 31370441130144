export const TILE_SIZE = 16

export const FONTS = {
  DEFAULT: 'Droid Sans',
  HEADER: 'Droid Serif',
  TITLE: 'Bangers'
}

export const SCENES = {
  TITLE: 'Title',
  WORLD: 'World',
  BOOT: 'Boot',
  LOADING: 'Loading',
  HUD: 'HUD',
  GAME_OPTIONS: 'GameOptions'
}

export const EVENTS = {
  UNIT_SELECTED: 'unitSelected',
  DISPLAY_HUD: 'displayHUD',
  START_GAME: 'startGame',
  UPDATE_SOLDIER_STATS: 'updateSoldierStats',
  DOWN: 'down',
  POINTER_UP: 'pointerup'
}

export const GAME_STATE = {
  PAUSED: 'paused',
  NOT_STARTED: 'notStarted',
  STARTED: 'started',
  GAME_OVER: 'gameOver'
}

export const COVER = {
  NONE: 'none',
  HALF: 'half',
  FULL: 'full'
}

export const KEYBOARD = {
  PAUSE_GAME: 'Space',
  DEBUG: 'Q',
  CANCEL_SELECTION: 'Esc'
}

export const MILITARY_RANKS = {
  PRIVATE: 'private',
  CORPORAL: 'corporal',
  SERGEANT: 'sergeant',
  LIEUTENANT: 'lieutenant',
  CAPTAIN: 'captain',
  MAJOR: 'major',
  COLONEL: 'colonel',
  GENERAL: 'general'
}
